import axios from 'axios'
import store from '../store'
import router from '../router'
import setting from '../config/setting'
import {
  Modal
} from 'ant-design-vue'

const service = axios.create({
  baseURL: setting.apiUrl
})

/* 添加请求拦截器 */
service.interceptors.request.use((config) => {
  // 添加token到header
  config.headers.tenantid = setting.getTenantId()
  const token = setting.getToken()
  if (token) {
    config.headers[setting.tokenHeaderName] = token
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

/* 添加响应拦截器 */
service.interceptors.response.use((res) => {
  // 登录过期处理
  if (res.data.code === 401) {
    if (res.config.url === setting.menuUrl) {
      goLogin()
    } else {
      Modal.destroyAll()
      Modal.info({
        title: '系统提示',
        content: '登录状态已过期, 请退出重新登录!',
        okText: '重新登录',
        onOk: () => {
          goLogin(true)
        }
      })
    }
    return Promise.reject(new Error(res.data.msg))
  }
  // token自动续期
  const accessToken = res.headers[setting.tokenHeaderName]
  if (accessToken) {
    setting.setToken(accessToken)
  }
  return res
}, (error) => {
  return Promise.reject(error)
})

service.json2xForm = (data) => {
  let xForm = ''
  Object.keys(data).forEach(key => {
    if (xForm.length > 0) {
      xForm += '&'
    }
    xForm += key + '=' + encodeURIComponent(data[key])
  })
  return xForm
}
/**
 * 跳转到登录页面
 */
function goLogin(reload) {
  store.dispatch('user/removeToken').then(() => {
    if (reload) {
      location.replace('/login') // 这样跳转避免再次登录重复注册动态路由
    } else {
      const path = router.currentRoute.path
      return router.push({
        path: '/login',
        query: path && path !== '/' ? {
          form: path
        } : null
      })
    }
  })
}

export default service
